import React from 'react';
import { graphql, Link } from 'gatsby';
import Seo from "../components/seo";
import Navigation from '../components/Navigation';
import Footer from '../components/Footer';

const BlogPostTemplate = ({ data }) => {
  if (!data?.markdownRemark) {
    return (
      <>
        <Navigation />
        <div className="min-h-screen py-12">
          <div className="max-w-3xl mx-auto px-4">
            <h1>Post not found</h1>
          </div>
        </div>
        <Footer />
      </>
    );
  }

  const post = data.markdownRemark;
  const { frontmatter, html, excerpt } = post;
  const seoDescription = frontmatter.description || excerpt;
  const recentPosts = data.recentPosts?.edges || [];

  return (
    <>
      <Seo title={frontmatter.title} description={seoDescription} />
      <Navigation />
      <div className="min-h-screen py-12">
        <div className="max-w-3xl mx-auto px-4 md:px-0">
          <article className="space-y-8 bg-white overflow-hidden">
            <header className="text-center space-y-6 px-3 md:px-0 text-gray-800">
              <h1 className="text-4xl md:text-5xl font-extrabold leading-tight tracking-tight">{frontmatter.title}</h1>
              <p className="text-lg text-gray-600 italic">{frontmatter.date}</p>
              {frontmatter.tags && (
                <div className="flex flex-wrap gap-2 justify-center">
                  {frontmatter.tags.map(tag => (
                    <Link
                      key={tag}
                      to={`/tags/${tag.toLowerCase().replace(/\s+/g, '-')}`}
                      className="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 hover:bg-gray-300"
                    >
                      #{tag}
                    </Link>
                  ))}
                </div>
              )}
            </header>
            <div className="px-6 md:px-0 py-8">
              <section 
                className="prose prose-lg md:prose-xl max-w-none text-gray-800 leading-relaxed markdown
                          prose-headings:font-bold prose-headings:tracking-tight prose-headings:text-gray-900
                          prose-p:text-gray-700 prose-p:leading-relaxed
                          prose-a:text-gray-700 prose-a:hover:text-black prose-a:no-underline hover:prose-a:underline
                          prose-strong:text-gray-900 prose-strong:font-bold
                          prose-code:text-gray-800 prose-code:bg-gray-100 prose-code:px-1 prose-code:rounded
                          prose-pre:bg-gray-900 prose-pre:text-gray-100
                          prose-img:rounded-lg prose-img:shadow-md
                          prose-blockquote:border-l-4 prose-blockquote:border-gray-300 prose-blockquote:text-gray-600 prose-blockquote:italic
                          prose-li:text-gray-700"
                dangerouslySetInnerHTML={{ __html: html }} 
              />
            </div>
            {recentPosts.length > 0 && (
              <div className="px-6 md:px-12 py-8 bg-gray-50 border-t border-gray-200">
                <h2 className="text-2xl font-bold mb-6 text-gray-900">Recent Posts</h2>
                <ul className="space-y-4">
                  {recentPosts.map(({ node }) => (
                    <li key={node.fields.slug} className="group">
                      <Link 
                        to={node.fields.slug} 
                        className="block p-4 rounded-lg hover:bg-white transition-all duration-300"
                      >
                        <h3 className="text-lg font-semibold text-gray-800 group-hover:text-black transition-colors duration-300">
                          {node.frontmatter.title}
                        </h3>
                        <p className="text-sm text-gray-600 mt-1">{node.excerpt}</p>
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </article>
          <Footer />
        </div>
      </div>
    </>
  );
};

export default BlogPostTemplate;

export const query = graphql`
  query BlogPostBySlug($id: String!, $slug: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      excerpt(pruneLength: 160)
      frontmatter {
        title
        date(formatString: "MMMM Do, YYYY")
        description
        tags
      }
      fileAbsolutePath
    }
    recentPosts: allMarkdownRemark(
      limit: 3
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { 
        fields: { slug: { ne: $slug } }
        fileAbsolutePath: { regex: "/content/blog/" }
      }
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
            tags
          }
          excerpt(pruneLength: 160)
        }
      }
    }
  }
`;
